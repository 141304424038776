import Test from '../src/Components/practiceTests/testComponent'
import './Testing.css'

const Testing = () => {
  return (
    <div className="testing-container">
      <Test />
    </div>
  );
}

export default Testing;
